'use client'

import UserConfig from '../user'
import { Menu, XIcon } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import { lazy, useState } from 'react'

import navLinks from '@/mocks/nav-links'

const Cart = lazy(() => import('@/components/cart'))

export default function Header() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

    return (
        <header className="fixed left-0 right-0 top-0 z-50 bg-background py-4 shadow-md lg:px-8 xl:px-0">
            <div className="container mx-auto max-w-[1128px] lg:px-0">
                <div className="flex h-16 items-center justify-between">
                    <div className="flex-shrink-0">
                        <Link href="/">
                            <Image
                                src="/logo.png"
                                alt="Logo"
                                width={114}
                                height={50}
                            />
                        </Link>
                    </div>

                    <nav className="hidden space-x-4 md:flex">
                        {navLinks.map((item, index) => (
                            <Link
                                href={item.href}
                                className="font-sans text-sm font-medium text-[#1E1E1E] hover:text-primary"
                                key={index}
                            >
                                {item.title}
                            </Link>
                        ))}
                    </nav>

                    <div className="hidden items-center gap-8 md:flex">
                        <Cart color="#000" />
                        <UserConfig />
                    </div>

                    <div className="flex items-center gap-4 md:hidden">
                        <div
                            role="button"
                            onClick={() => setIsMobileMenuOpen(false)}
                            className="flex items-center"
                        >
                            <Cart color="#000" />
                        </div>
                        <button
                            onClick={() =>
                                setIsMobileMenuOpen(!isMobileMenuOpen)
                            }
                            className="inline-flex items-center justify-center rounded-md p-2 text-muted-foreground"
                        >
                            <span className="sr-only">Open main menu</span>
                            {!isMobileMenuOpen && (
                                <Menu
                                    className="block h-6 w-6"
                                    aria-hidden="true"
                                />
                            )}
                            {isMobileMenuOpen && (
                                <XIcon
                                    className="block h-6 w-6"
                                    aria-hidden="true"
                                />
                            )}
                        </button>
                    </div>
                </div>
            </div>

            {isMobileMenuOpen && (
                <div className="md:hidden">
                    <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                        {navLinks.map((item, index) => (
                            <Link
                                href={item.href}
                                className="block rounded-md px-3 py-2 text-base font-medium text-muted-foreground hover:bg-muted hover:text-primary"
                                key={index}
                                onClick={() => setIsMobileMenuOpen(false)}
                            >
                                {item.title}
                            </Link>
                        ))}
                    </div>

                    <UserConfig />
                </div>
            )}
        </header>
    )
}
