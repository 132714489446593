const navLinks = [
    {
        id: 3,
        title: 'Book Classes',
        href: '/class',
    },
    {
        id: 1,
        title: 'The Experience',
        href: '/#experience',
    },
    {
        id: 4,
        title: 'FAQ',
        href: '/#faq',
    },
    {
        id: 2,
        title: 'Contact us',
        href: '/contact-us',
    },
]

export default navLinks
