import CustomAvatar from '../custom-avatar'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { LogOut, TicketSlash, User } from 'lucide-react'
import { signOut, useSession } from 'next-auth/react'
import Link from 'next/link'

export default function UserConfig() {
    const { data, status }: any = useSession()

    return (
        <div className="flex items-center gap-6 px-5 md:px-0">
            {status === 'unauthenticated' && (
                <>
                    <Link href="/sign-in">Sign in</Link>
                    <Link href="/sign-up">
                        <button className="gradient">Sign Up</button>
                    </Link>
                </>
            )}

            {status === 'authenticated' && data.user && (
                <>
                    <div className="hidden md:flex">
                        <DropdownMenu>
                            <DropdownMenuTrigger>
                                <CustomAvatar
                                    data={data}
                                    status="online"
                                    size="sm"
                                />
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuLabel>
                                    My Account
                                </DropdownMenuLabel>
                                <DropdownMenuSeparator />
                                {data.isAdmin && (
                                    <Link href="/admin">
                                        <DropdownMenuItem className="cursor-pointer">
                                            <User className="mr-2 h-4 w-4" />
                                            Admin Panel
                                        </DropdownMenuItem>
                                    </Link>
                                )}
                                <DropdownMenuSeparator />
                                <Link href="/tickets">
                                    <DropdownMenuItem className="cursor-pointer">
                                        <TicketSlash className="mr-2 h-4 w-4" />
                                        My Tickets
                                    </DropdownMenuItem>
                                </Link>
                                <DropdownMenuSeparator />
                                <DropdownMenuItem
                                    onClick={() => signOut()}
                                    className="cursor-pointer"
                                >
                                    <LogOut className="mr-2 h-4 w-4" />
                                    <span>Log out</span>
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>

                    <div className="border-t border-muted pb-3 pt-4 md:hidden">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <CustomAvatar
                                    data={data}
                                    status="online"
                                    size="sm"
                                />
                            </div>
                            <div className="ml-3">
                                <div className="text-base font-medium text-primary">
                                    {data.user.name?.toUpperCase()}
                                </div>
                                <div className="text text-sm font-medium text-muted-foreground">
                                    {data.user.email}
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 space-y-1">
                            {data.isAdmin && (
                                <Link
                                    href="/admin"
                                    className="block w-full rounded-md py-2 text-base font-medium text-muted-foreground hover:bg-muted hover:text-primary"
                                >
                                    Admin Panel
                                </Link>
                            )}
                            <Link
                                href="/tickets"
                                className="block w-full rounded-md py-2 text-base font-medium text-muted-foreground hover:bg-muted hover:text-primary"
                            >
                                My Tickets
                            </Link>
                            <Link
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault()
                                    signOut()
                                }}
                                className="block w-full rounded-md py-2 text-base font-medium text-muted-foreground hover:bg-muted hover:text-primary"
                            >
                                Logout
                            </Link>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
