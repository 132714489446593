import { useState } from 'react'

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'

export default function CustomAvatar({
    data,
    size = 'md',
    status,
}: {
    data?: any
    size?: 'sm' | 'md' | 'lg'
    status?: 'online' | 'offline' | 'away'
} = {}) {
    const [isHovered, setIsHovered] = useState<boolean>(false)

    const sizeClasses = {
        sm: 'h-10 w-10',
        md: 'h-16 w-16',
        lg: 'h-24 w-24',
    }

    const statusColors = {
        online: 'bg-green-500',
        offline: 'bg-gray-500',
        away: 'bg-yellow-500',
    }

    return (
        <div
            className="relative inline-block"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Avatar
                className={` ${sizeClasses[size]} border transition-all duration-300 ease-in-out ${isHovered ? 'scale-110 shadow-lg' : ''} `}
            >
                {data.user.image && (
                    <AvatarImage src={data.user.image} alt={data.user.name} />
                )}
                {data.user.name && (
                    <AvatarFallback className="bg-secondary text-secondary-foreground">
                        {data.user.name[0]}
                    </AvatarFallback>
                )}
            </Avatar>
            {status && (
                <span
                    className={`absolute bottom-0 right-0 block rounded-full ${statusColors[status]} ${size === 'sm' ? 'h-3 w-3' : size === 'md' ? 'h-4 w-4' : 'h-5 w-5'} ring-2 ring-white`}
                />
            )}
        </div>
    )
}
